export const getRefinement = (params) => window._http('/Goods-refinement', params)

export const getMaterialList = (params) => window._http('/MaterialList-goods', params)

export const getShareContent = (params) => window._http('/ShareContent-index', params)

export const getGoodsArticle = (params) => window._http('/GoodsArticle-index', params)

export const getGoodsArticleItem = (params) => window._http('/GoodsArticle-item', params)

export const getGoodsUrl = (params) => window._http('/Item-url', params)